import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import ABackbutton from '../components/a-backbutton'
import CloudinarySlider from '../components/cloudinary-slider'
import './cloudinary.css'

const Cloudinary = (props) => {
  return (
    <div className="cloudinary-container">
      <Helmet>
        <title>Cloudinary - Seite105</title>
        <meta
          name="description"
          content="Poket-Leitfaden für die Gestaltung nachhaltiger Websites und die Optimierung bestehender Websites."
        />
        <meta property="og:title" content="Cloudinary - Seite105" />
        <meta
          property="og:description"
          content="Poket-Leitfaden für die Gestaltung nachhaltiger Websites und die Optimierung bestehender Websites."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/9e8648d5-9531-4645-8963-7d21b56f74f1/c1b709ce-0eea-4ff9-90e3-ed7939e7c0e8?org_if_sml=1&amp;q=80&amp;force_format=original"
        />
      </Helmet>
      <Link to="/analyse" className="cloudinary-navlink">
        <ABackbutton className="cloudinary-component"></ABackbutton>
      </Link>
      <CloudinarySlider></CloudinarySlider>
    </div>
  )
}

export default Cloudinary
