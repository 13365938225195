import React, { useState } from 'react'

import { Player } from '@lottiefiles/react-lottie-player'
import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import GiftofspeedEx1 from './giftofspeed-ex1'
import './giftofspeed-slider.css'

const GiftofspeedSlider = (props) => {
  const [slideNumber, setSlideNumber] = useState(1)
  return (
    <div className={`giftofspeed-slider-container ${props.rootClassName} `}>
      <div className="giftofspeed-slider-container01">
        <div className="giftofspeed-slider-sliderpanel">
          {slideNumber === 1 && (
            <div className="giftofspeed-slider-container02">
              <div className="giftofspeed-slider-storyfortschrit">
                <div className="giftofspeed-slider-activ"></div>
                <div className="giftofspeed-slider-passiv"></div>
              </div>
              <div
                onClick={() => setSlideNumber(2)}
                className="giftofspeed-slider-larrow finger"
              ></div>
              <div
                onClick={() => setSlideNumber(2)}
                className="giftofspeed-slider-rarrow finger"
              >
                <Player
                  id="one"
                  src="https://storage.googleapis.com/playground-bucket-v2.teleporthq.io/9e8648d5-9531-4645-8963-7d21b56f74f1/d18d188e-0ec7-4e8d-917d-68e21a473683"
                  loop
                  speed="0.5"
                  autoplay
                  background="transparent"
                  className="giftofspeed-slider-lottie-node"
                ></Player>
              </div>
              <h1 className="headline">{props.heading}</h1>
              <div className="giftofspeed-slider-container03">
                <div className="giftofspeed-slider-container04">
                  <span className="giftofspeed-slider-text1 text">
                    {props.text}
                  </span>
                </div>
              </div>
              <GiftofspeedEx1 className=""></GiftofspeedEx1>
            </div>
          )}
          {slideNumber === 2 && (
            <div className="giftofspeed-slider-container05">
              <div className="giftofspeed-slider-storyfortschrit1">
                <div className="giftofspeed-slider-passiv1"></div>
                <div className="giftofspeed-slider-activ1"></div>
              </div>
              <div
                onClick={() => setSlideNumber(1)}
                className="giftofspeed-slider-larrow1 finger"
              ></div>
              <div
                onClick={() => setSlideNumber(1)}
                className="giftofspeed-slider-rarrow1 finger"
              ></div>
              <h1 className="headline">{props.heading1}</h1>
              <div className="giftofspeed-slider-container06">
                <div className="giftofspeed-slider-container07">
                  <div className="giftofspeed-slider-container08">
                    <video
                      src={props.videoSrc1}
                      poster="/thumb.svg"
                      controls="true"
                      className="giftofspeed-slider-video"
                    ></video>
                    <span className="giftofspeed-slider-text3 text">
                      {props.text1}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="">
        <div className="giftofspeed-slider-container10">
          <Script
            html={`<script>
var element = document.getElementById('one');
if (element) {
  element.style.display = 'block';
  setTimeout(function() {
    element.style.display = 'none';
  }, 2800);
}
</script>`}
            className=""
          ></Script>
        </div>
      </div>
    </div>
  )
}

GiftofspeedSlider.defaultProps = {
  heading1: 'Video zur Anwendung',
  text1: '~1,59MB 1:22min.',
  videoSrc1: '/tutvid/microgiftofspeed.mp4',
  heading: 'giftofspeed',
  rootClassName: '',
  text: 'giftofspeed prüft, ob eine Website brotli, gzip oder keines von beiden verwendet.',
}

GiftofspeedSlider.propTypes = {
  heading1: PropTypes.string,
  text1: PropTypes.string,
  videoSrc1: PropTypes.string,
  heading: PropTypes.string,
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default GiftofspeedSlider
