import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import AHeadline from '../components/a-headline'
import ABackbutton from '../components/a-backbutton'
import './impressum.css'

const Impressum = (props) => {
  return (
    <div className="impressum-container">
      <Helmet>
        <title>Impressum - Seite105</title>
        <meta
          name="description"
          content="Poket-Leitfaden für die Gestaltung nachhaltiger Websites und die Optimierung bestehender Websites."
        />
        <meta property="og:title" content="Impressum - Seite105" />
        <meta
          property="og:description"
          content="Poket-Leitfaden für die Gestaltung nachhaltiger Websites und die Optimierung bestehender Websites."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/9e8648d5-9531-4645-8963-7d21b56f74f1/c1b709ce-0eea-4ff9-90e3-ed7939e7c0e8?org_if_sml=1&amp;q=80&amp;force_format=original"
        />
      </Helmet>
      <AHeadline rootClassName="a-headline-root-class-name9"></AHeadline>
      <span className="impressum-text">
        <span>███████╗███████╗██╗████████╗███████╗  ██╗ ██████╗ ███████╗</span>
        <br></br>
        <br></br>
        <span>██╔════╝██╔════╝██║╚══██╔══╝██╔════╝ ███║██╔═████╗██╔════╝</span>
        <br></br>
        <br></br>
        <span>███████╗█████╗  ██║   ██║   █████╗   ╚██║██║██╔██║███████╗</span>
        <br></br>
        <br></br>
        <span>╚════██║██╔══╝  ██║   ██║   ██╔══╝    ██║████╔╝██║╚════██║</span>
        <br></br>
        <br></br>
        <span>███████║███████╗██║   ██║   ███████╗  ██║╚██████╔╝███████║</span>
        <br></br>
        <br></br>
        <span>╚══════╝╚══════╝╚═╝   ╚═╝   ╚══════╝  ╚═╝ ╚═════╝ ╚══════╝</span>
      </span>
      <div className="impressum-container1">
        <div className="impressum-container2">
          <h1 className="impressum-text17">Impressum</h1>
          <span className="impressum-text18 Content">
            <span>Angaben gemäß § 5 TMG</span>
            <br></br>
            <br></br>
            <span>Max Präkelt</span>
            <br></br>
            <span>Bechtsbüttelerstraße 5</span>
            <br></br>
            <span>38110 Braunschweig</span>
            <br></br>
            <br></br>
            <span>Kontakt</span>
            <br></br>
            <span>Telefon: 015785448763</span>
            <br></br>
            <span>E-Mail: m@xpraekelt.de</span>
            <br></br>
            <br></br>
            <span>Redaktionell verantwortlich</span>
            <br></br>
            <br></br>
            <span>Max Präkelt</span>
            <br></br>
            <br></br>
            <span>Quelle: https://www.e-recht24.de</span>
          </span>
        </div>
        <div className="impressum-container3">
          <h1 className="impressum-text43">Mentions</h1>
          <span className="impressum-text44">
            <span className="impressum-text45">Createt with TeleportHQ</span>
            <br className="impressum-text46"></br>
            <span className="impressum-text47">https://teleporthq.io</span>
            <br className="impressum-text48"></br>
            <br className="impressum-text49"></br>
            <span className="impressum-text50">typed.js by Matt Boldt</span>
            <br className="impressum-text51"></br>
            <span className="impressum-text52">https://mattboldt.com</span>
            <br></br>
            <br></br>
            <span>Icons </span>
            <br></br>
            <span>https://thenounproject.com</span>
            <br></br>
            <br></br>
            <span>
              Lottiefiles
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>https://creattie.com</span>
            <br></br>
            <br></br>
            <span>Alle Grafiken lizenziert.</span>
            <br></br>
            <br></br>
          </span>
        </div>
      </div>
      <Link to="/" className="impressum-navlink">
        <ABackbutton
          rootClassName="a-backbutton-root-class-name2"
          className="impressum-component1"
        ></ABackbutton>
      </Link>
    </div>
  )
}

export default Impressum
