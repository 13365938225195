import React, { useState } from 'react'

import { Player } from '@lottiefiles/react-lottie-player'
import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import BeaconEx1 from './beacon-ex1'
import './beacon-slider.css'

const BeaconSlider = (props) => {
  const [slideNumber, setSlideNumber] = useState(1)
  return (
    <div className={`beacon-slider-container ${props.rootClassName} `}>
      <div className="beacon-slider-container01">
        <div className="beacon-slider-sliderpanel">
          {slideNumber === 1 && (
            <div className="beacon-slider-container02">
              <div className="beacon-slider-storyfortschrit">
                <div className="beacon-slider-activ"></div>
                <div className="beacon-slider-passiv"></div>
              </div>
              <div
                onClick={() => setSlideNumber(2)}
                className="beacon-slider-larrow finger"
              ></div>
              <div
                onClick={() => setSlideNumber(2)}
                className="beacon-slider-rarrow finger"
              >
                <Player
                  id="one"
                  src="https://storage.googleapis.com/playground-bucket-v2.teleporthq.io/9e8648d5-9531-4645-8963-7d21b56f74f1/d18d188e-0ec7-4e8d-917d-68e21a473683"
                  loop
                  speed="0.5"
                  autoplay
                  background="transparent"
                  className="beacon-slider-lottie-node"
                ></Player>
              </div>
              <h1 className="headline">{props.heading}</h1>
              <div className="beacon-slider-container03">
                <div className="beacon-slider-container04">
                  <span className="beacon-slider-text1 text">{props.text}</span>
                </div>
              </div>
              <BeaconEx1 className=""></BeaconEx1>
            </div>
          )}
          {slideNumber === 2 && (
            <div className="beacon-slider-container05">
              <div className="beacon-slider-storyfortschrit1">
                <div className="beacon-slider-passiv1"></div>
                <div className="beacon-slider-activ1"></div>
              </div>
              <div
                onClick={() => setSlideNumber(1)}
                className="beacon-slider-larrow1 finger"
              ></div>
              <div
                onClick={() => setSlideNumber(1)}
                className="beacon-slider-rarrow1 finger"
              ></div>
              <h1 className="headline">{props.heading1}</h1>
              <div className="beacon-slider-container06">
                <div className="beacon-slider-container07">
                  <div className="beacon-slider-container08">
                    <video
                      src={props.videoSrc1}
                      poster="/thumb.svg"
                      controls="true"
                      className="beacon-slider-video"
                    ></video>
                    <span className="beacon-slider-text3 text">
                      {props.text1}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="">
        <div className="beacon-slider-container10">
          <Script
            html={`<script>
var element = document.getElementById('one');
if (element) {
  element.style.display = 'block';
  setTimeout(function() {
    element.style.display = 'none';
  }, 2800);
}
</script>`}
            className=""
          ></Script>
        </div>
      </div>
    </div>
  )
}

BeaconSlider.defaultProps = {
  imageAlt3: 'image',
  heading: 'Beacon',
  imageSrc3: '83ed1138-5d57-41de-8777-9cadc07ced60',
  videoSrc1: '/tutvid/microbeacon.mp4',
  imageAlt11: 'image',
  imageSrc1: 'd9529113-0567-4100-aeeb-be2abe507e90',
  text: 'Beacon ist ein Tool, das dem Carbon Calculator sehr ähnlich ist, auch hier erhält man eine Note für CO2 Sachen. Relevanter ist hier jedoch die Gesamtdateigröße der Webseite und wie sich diese zusammensetzt, sowie die Effizienz des Caching der Seite, falls vorhanden.',
  imageSrc11:
    'https://images.unsplash.com/photo-1682687982501-1e58ab814714?ixid=M3w5MTMyMXwxfDF8YWxsfDE2fHx8fHx8Mnx8MTY5Mzc2NTg4Nnw&ixlib=rb-4.0.3&w=1500',
  text1: '~9,38MB 6:30min.',
  imageSrc111:
    'https://images.unsplash.com/photo-1693520999631-6ac145c1dd15?ixid=M3w5MTMyMXwwfDF8YWxsfDE3fHx8fHx8Mnx8MTY5Mzc2NTg4Nnw&ixlib=rb-4.0.3&w=1500',
  imageAlt111: 'image',
  imageAlt: 'image',
  rootClassName: '',
  heading1: 'Video zur Anwendung',
  imageAlt1: 'image',
  imageSrc: '01cffccf-12b5-4830-81c3-bb46b3b70f37',
  videoSrc: '5805aca5-f521-4546-9c1d-105cf11e6185',
}

BeaconSlider.propTypes = {
  imageAlt3: PropTypes.string,
  heading: PropTypes.string,
  imageSrc3: PropTypes.string,
  videoSrc1: PropTypes.string,
  imageAlt11: PropTypes.string,
  imageSrc1: PropTypes.string,
  text: PropTypes.string,
  imageSrc11: PropTypes.string,
  text1: PropTypes.string,
  imageSrc111: PropTypes.string,
  imageAlt111: PropTypes.string,
  imageAlt: PropTypes.string,
  rootClassName: PropTypes.string,
  heading1: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageSrc: PropTypes.string,
  videoSrc: PropTypes.string,
}

export default BeaconSlider
