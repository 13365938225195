import React from 'react'

import PropTypes from 'prop-types'

import './a-headline.css'

const AHeadline = (props) => {
  return (
    <div className={`a-headline-headline ${props.rootClassName} `}>
      <span className="a-headline-text">
        <span className="">{props.text22}</span>
        <br className=""></br>
        <br className=""></br>
        <span className="">{props.text23}</span>
        <br className=""></br>
        <br className=""></br>
        <span className="">{props.text24}</span>
        <br className=""></br>
        <br className=""></br>
        <span className="">{props.text25}</span>
        <br className=""></br>
        <br className=""></br>
        <span className="">{props.text26}</span>
        <br className=""></br>
        <br className=""></br>
        <span className="">{props.text27}</span>
        <br className=""></br>
        <br className=""></br>
      </span>
    </div>
  )
}

AHeadline.defaultProps = {
  text26: '███████║███████╗██║   ██║   ███████╗     ██║╚██████╔╝███████║',
  text19: '███████║███████╗██║   ██║   ███████╗     ██║╚██████╔╝███████║',
  text23: '██╔════╝██╔════╝██║╚══██╔══╝██╔════╝    ███║██╔═████╗██╔════╝',
  text21: '██╔════╝██╔════╝██║╚══██╔══╝██╔════╝    ███║██╔═████╗██╔════╝',
  text11: '███████╗███████╗██╗████████╗███████╗  ██╗ ██████╗ ███████╗',
  text25: '╚════██║██╔══╝  ██║   ██║   ██╔══╝       ██║████╔╝██║╚════██║',
  text8: '██╔════╝██╔════╝██║╚══██╔══╝██╔════╝    ███║██╔═████╗██╔════╝',
  text10: '╚════██║██╔══╝  ██║   ██║   ██╔══╝       ██║████╔╝██║╚════██║',
  text61: '╚══════╝╚══════╝╚═╝   ╚═╝   ╚══════╝  ╚═╝ ╚═════╝ ╚══════╝',
  text9: '███████╗█████╗  ██║   ██║   █████╗      ╚██║██║██╔██║███████╗',
  text13: '╚══════╝╚══════╝╚═╝   ╚═╝   ╚══════╝     ╚═╝ ╚═════╝ ╚══════╝',
  text17: '███████╗█████╗  ██║   ██║   █████╗      ╚██║██║██╔██║███████╗',
  text27: '╚══════╝╚══════╝╚═╝   ╚═╝   ╚══════╝     ╚═╝ ╚═════╝ ╚══════╝',
  text14: '                                                             ',
  text16: '██╔════╝██╔════╝██║╚══██╔══╝██╔════╝    ███║██╔═████╗██╔════╝',
  text20: '╚══════╝╚══════╝╚═╝   ╚═╝   ╚══════╝     ╚═╝ ╚═════╝ ╚══════╝',
  text7: '███████╗███████╗██╗████████╗███████╗     ██╗ ██████╗ ███████╗',
  text18: '╚════██║██╔══╝  ██║   ██║   ██╔══╝       ██║████╔╝██║╚════██║',
  text12: '███████║███████╗██║   ██║   ███████╗     ██║╚██████╔╝███████║',
  text31: '███████╗█████╗  ██║   ██║   █████╗   ╚██║██║██╔██║███████╗',
  text24: '███████╗█████╗  ██║   ██║   █████╗      ╚██║██║██╔██║███████╗',
  text51: '███████║███████╗██║   ██║   ███████╗  ██║╚██████╔╝███████║',
  text41: '╚════██║██╔══╝  ██║   ██║   ██╔══╝    ██║████╔╝██║╚════██║',
  text22: '███████╗███████╗██╗████████╗███████╗     ██╗ ██████╗ ███████╗',
  text15: '███████╗███████╗██╗████████╗███████╗     ██╗ ██████╗ ███████╗',
  text5: '███████║███████╗██    ██║   ███████╗  ██║╚██████╔╝███████║',
  text2: '██╔════╝██╔════╝██║╚══██╔══╝██╔════╝    ███║██╔═████╗██╔════╝',
  text1: '███████╗███████╗██╗████████╗███████╗  ██╗ ██████╗ ███████╗',
  text6: '╚══════╝╚══════╝╚═╝   ╚═╝   ╚══════╝  ╚═╝ ╚═════╝ ╚══════╝',
  text3: '███████╗█████╗  ██║   ██║   █████╗   ╚██║██║██╔██║███████╗',
  text4: '╚════██║██╔══╝  ██║   ██║   ██╔══╝    ██║████╔╝██║╚════██║',
  rootClassName: '',
  text: 'Text',
}

AHeadline.propTypes = {
  text26: PropTypes.string,
  text19: PropTypes.string,
  text23: PropTypes.string,
  text21: PropTypes.string,
  text11: PropTypes.string,
  text25: PropTypes.string,
  text8: PropTypes.string,
  text10: PropTypes.string,
  text61: PropTypes.string,
  text9: PropTypes.string,
  text13: PropTypes.string,
  text17: PropTypes.string,
  text27: PropTypes.string,
  text14: PropTypes.string,
  text16: PropTypes.string,
  text20: PropTypes.string,
  text7: PropTypes.string,
  text18: PropTypes.string,
  text12: PropTypes.string,
  text31: PropTypes.string,
  text24: PropTypes.string,
  text51: PropTypes.string,
  text41: PropTypes.string,
  text22: PropTypes.string,
  text15: PropTypes.string,
  text5: PropTypes.string,
  text2: PropTypes.string,
  text1: PropTypes.string,
  text6: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default AHeadline
