import React, { useState } from 'react'

import { Player } from '@lottiefiles/react-lottie-player'
import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import CarbonClaculatorEx1 from './carbon-claculator-ex1'
import './carbon-claculator-slider.css'

const CarbonClaculatorSlider = (props) => {
  const [slideNumber, setSlideNumber] = useState(1)
  return (
    <div
      className={`carbon-claculator-slider-container ${props.rootClassName} `}
    >
      <div className="carbon-claculator-slider-container01">
        <div className="carbon-claculator-slider-sliderpanel">
          {slideNumber === 1 && (
            <div className="carbon-claculator-slider-container02">
              <div className="carbon-claculator-slider-storyfortschrit">
                <div className="carbon-claculator-slider-activ"></div>
                <div className="carbon-claculator-slider-passiv"></div>
              </div>
              <div
                onClick={() => setSlideNumber(2)}
                className="carbon-claculator-slider-larrow finger"
              ></div>
              <div
                onClick={() => setSlideNumber(2)}
                className="carbon-claculator-slider-rarrow finger"
              >
                <Player
                  id="one"
                  src="https://storage.googleapis.com/playground-bucket-v2.teleporthq.io/9e8648d5-9531-4645-8963-7d21b56f74f1/d18d188e-0ec7-4e8d-917d-68e21a473683"
                  loop
                  speed="0.5"
                  autoplay
                  background="transparent"
                  className="carbon-claculator-slider-lottie-node"
                ></Player>
              </div>
              <h1 className="headline">{props.heading}</h1>
              <div className="carbon-claculator-slider-container03">
                <div className="carbon-claculator-slider-container04">
                  <span className="carbon-claculator-slider-text1 text">
                    {props.text}
                  </span>
                </div>
              </div>
              <CarbonClaculatorEx1 className=""></CarbonClaculatorEx1>
            </div>
          )}
          {slideNumber === 2 && (
            <div className="carbon-claculator-slider-container05">
              <div className="carbon-claculator-slider-storyfortschrit1">
                <div className="carbon-claculator-slider-passiv1"></div>
                <div className="carbon-claculator-slider-activ1"></div>
              </div>
              <div
                onClick={() => setSlideNumber(1)}
                className="carbon-claculator-slider-larrow1 finger"
              ></div>
              <div
                onClick={() => setSlideNumber(1)}
                className="carbon-claculator-slider-rarrow1 finger"
              ></div>
              <h1 className="headline">{props.heading1}</h1>
              <div className="carbon-claculator-slider-container06">
                <div className="carbon-claculator-slider-container07">
                  <div className="carbon-claculator-slider-container08">
                    <video
                      src={props.videoSrc1}
                      poster="/thumb.svg"
                      controls="true"
                      className="carbon-claculator-slider-video"
                    ></video>
                    <span className="carbon-claculator-slider-text3 text">
                      {props.text1}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="">
        <div className="carbon-claculator-slider-container10">
          <Script
            html={`<script>
var element = document.getElementById('one');
if (element) {
  element.style.display = 'block';
  setTimeout(function() {
    element.style.display = 'none';
  }, 2800);
}
</script>`}
            className=""
          ></Script>
        </div>
      </div>
    </div>
  )
}

CarbonClaculatorSlider.defaultProps = {
  rootClassName: '',
  text1: '~7,44MB 3:40min.',
  videoSrc1: '/tutvid/microcarbon.mp4',
  heading1: 'Video zur Anwendung',
  heading: 'Website Carbon Calculator',
  text: 'Der Rechner kann vor allem einen ersten Überblick über den Energieverbrauch geben. Die Werte werden in CO2 angegeben, das aus der verbrauchten Energie berechnet wird. Darüber hinaus - und hier ist das Tool relevant - kann überprüft werden, ob sich ein Hoster bei der greenwebfoundation als nachhaltig registriert hat. in Deutschland sind derzeit 86 Anbieter registriert.',
}

CarbonClaculatorSlider.propTypes = {
  rootClassName: PropTypes.string,
  text1: PropTypes.string,
  videoSrc1: PropTypes.string,
  heading1: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
}

export default CarbonClaculatorSlider
