import React from 'react'

import { Helmet } from 'react-helmet'

import HALSlider from '../components/hal-slider'
import './steuerung.css'

const Steuerung = (props) => {
  return (
    <div className="steuerung-container">
      <Helmet>
        <title>Steuerung - Seite105</title>
        <meta
          name="description"
          content="Poket-Leitfaden für die Gestaltung nachhaltiger Websites und die Optimierung bestehender Websites."
        />
        <meta property="og:title" content="Steuerung - Seite105" />
        <meta
          property="og:description"
          content="Poket-Leitfaden für die Gestaltung nachhaltiger Websites und die Optimierung bestehender Websites."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/9e8648d5-9531-4645-8963-7d21b56f74f1/c1b709ce-0eea-4ff9-90e3-ed7939e7c0e8?org_if_sml=1&amp;q=80&amp;force_format=original"
        />
      </Helmet>
      <HALSlider rootClassName="hal-slider-root-class-name4"></HALSlider>
    </div>
  )
}

export default Steuerung
