import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import ABackbutton from '../components/a-backbutton'
import AHeadline from '../components/a-headline'
import './firefox.css'

const Firefox = (props) => {
  return (
    <div className="firefox-container">
      <Helmet>
        <title>Firefox - Seite105</title>
        <meta
          name="description"
          content="Poket-Leitfaden für die Gestaltung nachhaltiger Websites und die Optimierung bestehender Websites."
        />
        <meta property="og:title" content="Firefox - Seite105" />
        <meta
          property="og:description"
          content="Poket-Leitfaden für die Gestaltung nachhaltiger Websites und die Optimierung bestehender Websites."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/9e8648d5-9531-4645-8963-7d21b56f74f1/c1b709ce-0eea-4ff9-90e3-ed7939e7c0e8?org_if_sml=1&amp;q=80&amp;force_format=original"
        />
      </Helmet>
      <Link to="/analyse" className="firefox-navlink">
        <ABackbutton className="firefox-component"></ABackbutton>
      </Link>
      <AHeadline rootClassName="a-headline-root-class-name8"></AHeadline>
      <div className="firefox-container1">
        <div className="firefox-container2">
          <span className="firefox-text text">
            <span>
              Die Videos können mit Firefox ohne diese Änderungen nicht
              abgespielt werden:
            </span>
            <br></br>
            <span>Bei Firefox muss H265 aktiviert sein.</span>
            <br></br>
            <span>
              1. neuer Reiter: about:config
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>2. nach &quot;media.wmf.hevc.enabled&quot; suchen</span>
            <br></br>
            <span>3. den Wert von &quot;0&quot; auf &quot;1&quot; ändern</span>
            <br></br>
            <span>4. Browser neu starten.</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Firefox
