import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './animation-ex1.css'

const AnimationEx1 = (props) => {
  const [toggle, setToggle] = useState(false)
  return (
    <div className={`animation-ex1-container ${props.rootClassName} `}>
      {toggle && (
        <div
          onClick={() => setToggle(false)}
          className="animation-ex1-container1"
        >
          <svg viewBox="0 0 1024 1024" className="animation-ex1-icon">
            <path
              d="M366 708l196-196-196-196 60-60 256 256-256 256z"
              className=""
            ></path>
          </svg>
          <div className="animation-ex1-container2">
            <a
              href="https://lottiefiles.com/de/"
              target="_blank"
              rel="noreferrer noopener"
              className="animation-ex1-link text"
            >
              {props.text4}
            </a>
            <span className="animation-ex1-text text">{props.text41}</span>
            <a
              href="https://creattie.com"
              target="_blank"
              rel="noreferrer noopener"
              className="animation-ex1-link1 text"
            >
              {props.text31}
            </a>
            <span className="animation-ex1-text1 text">{props.text411}</span>
            <a
              href="https://www.lottielab.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="animation-ex1-link2 text"
            >
              {props.text311}
            </a>
          </div>
        </div>
      )}
      {!toggle && (
        <div
          onClick={() => setToggle(!toggle)}
          className="animation-ex1-container3"
        >
          <svg viewBox="0 0 1024 1024" className="animation-ex1-icon2">
            <path
              d="M658 708l-60 60-256-256 256-256 60 60-196 196z"
              className=""
            ></path>
          </svg>
        </div>
      )}
    </div>
  )
}

AnimationEx1.defaultProps = {
  text311: 'lottielab.com',
  text411:
    'Bester Freeware-Editor für Lottifiles 1000x leistungsstärker als Lottifiles 10/10',
  text4: 'lottiefiles.com',
  rootClassName: '',
  text31: 'creattie.com',
  text41:
    'Hier ist eine Quelle für kostenlose Lottiefiles neben der Lottiefiles-Seite selbst.',
}

AnimationEx1.propTypes = {
  text311: PropTypes.string,
  text411: PropTypes.string,
  text4: PropTypes.string,
  rootClassName: PropTypes.string,
  text31: PropTypes.string,
  text41: PropTypes.string,
}

export default AnimationEx1
