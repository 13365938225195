import React, { useState } from 'react'

import { Player } from '@lottiefiles/react-lottie-player'
import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import PagespeedlEx1 from './pagespeedl-ex1'
import './pagespeed-slider.css'

const PagespeedSlider = (props) => {
  const [slideNumber, setSlideNumber] = useState(1)
  return (
    <div className={`pagespeed-slider-container ${props.rootClassName} `}>
      <div className="pagespeed-slider-container01">
        <div className="pagespeed-slider-sliderpanel">
          {slideNumber === 1 && (
            <div className="pagespeed-slider-container02">
              <div className="pagespeed-slider-storyfortschrit">
                <div className="pagespeed-slider-activ"></div>
                <div className="pagespeed-slider-passiv"></div>
                <div className="pagespeed-slider-passiv01"></div>
                <div className="pagespeed-slider-passiv02"></div>
              </div>
              <div
                onClick={() => setSlideNumber(4)}
                className="pagespeed-slider-larrow finger"
              ></div>
              <div
                onClick={() => setSlideNumber(2)}
                className="pagespeed-slider-rarrow finger"
              >
                <Player
                  id="one"
                  src="https://storage.googleapis.com/playground-bucket-v2.teleporthq.io/9e8648d5-9531-4645-8963-7d21b56f74f1/d18d188e-0ec7-4e8d-917d-68e21a473683"
                  loop
                  speed="0.5"
                  autoplay
                  background="transparent"
                  className="pagespeed-slider-lottie-node"
                ></Player>
              </div>
              <h1 className="headline">{props.heading}</h1>
              <div className="pagespeed-slider-container03">
                <div className="pagespeed-slider-container04">
                  <span className="pagespeed-slider-text1 text">
                    {props.text}
                  </span>
                </div>
              </div>
              <PagespeedlEx1 className=""></PagespeedlEx1>
            </div>
          )}
          {slideNumber === 2 && (
            <div className="pagespeed-slider-container05">
              <div className="pagespeed-slider-storyfortschrit1">
                <div className="pagespeed-slider-passiv03"></div>
                <div className="pagespeed-slider-activ1"></div>
                <div className="pagespeed-slider-passiv04"></div>
                <div className="pagespeed-slider-passiv05"></div>
              </div>
              <div
                onClick={() => setSlideNumber(1)}
                className="pagespeed-slider-larrow1 finger"
              ></div>
              <div
                onClick={() => setSlideNumber(3)}
                className="pagespeed-slider-rarrow1 finger"
              ></div>
              <h1 className="headline">{props.heading1}</h1>
              <div className="pagespeed-slider-container06">
                <div className="pagespeed-slider-container07">
                  <div className="pagespeed-slider-container08">
                    <video
                      src={props.videoSrc1}
                      poster="/thumb.svg"
                      controls="true"
                      className="pagespeed-slider-video"
                    ></video>
                    <span className="pagespeed-slider-text3 text">
                      {props.text1}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {slideNumber === 3 && (
            <div className="pagespeed-slider-container09">
              <div className="pagespeed-slider-storyfortschrit2">
                <div className="pagespeed-slider-passiv06"></div>
                <div className="pagespeed-slider-passiv07"></div>
                <div className="pagespeed-slider-activ2"></div>
                <div className="pagespeed-slider-passiv08"></div>
              </div>
              <div
                onClick={() => setSlideNumber(2)}
                className="pagespeed-slider-larrow2 finger"
              ></div>
              <div
                onClick={() => setSlideNumber(4)}
                className="pagespeed-slider-rarrow2 finger"
              ></div>
              <h1 className="headline">{props.heading11}</h1>
              <div className="pagespeed-slider-container10">
                <div className="pagespeed-slider-container11">
                  <div className="pagespeed-slider-container12">
                    <video
                      src={props.videoSrc11}
                      poster="/thumb.svg"
                      controls="true"
                      className="pagespeed-slider-video1"
                    ></video>
                    <span className="pagespeed-slider-text5 text">
                      {props.text11}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {slideNumber === 4 && (
            <div className="pagespeed-slider-container13">
              <div className="pagespeed-slider-storyfortschrit3">
                <div className="pagespeed-slider-passiv09"></div>
                <div className="pagespeed-slider-passiv10"></div>
                <div className="pagespeed-slider-passiv11"></div>
                <div className="pagespeed-slider-activ3"></div>
              </div>
              <div
                onClick={() => setSlideNumber(3)}
                className="pagespeed-slider-larrow3 finger"
              ></div>
              <div
                onClick={() => setSlideNumber(1)}
                className="pagespeed-slider-rarrow3 finger"
              ></div>
              <h1 className="headline">{props.heading12}</h1>
              <div className="pagespeed-slider-container14">
                <div className="pagespeed-slider-container15">
                  <div className="pagespeed-slider-container16">
                    <video
                      src={props.videoSrc12}
                      poster="/thumb.svg"
                      controls="true"
                      className="pagespeed-slider-video2"
                    ></video>
                    <span className="pagespeed-slider-text7 text">
                      {props.text12}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="">
        <div className="pagespeed-slider-container18">
          <Script
            html={`<script>
var element = document.getElementById('one');
if (element) {
  element.style.display = 'block';
  setTimeout(function() {
    element.style.display = 'none';
  }, 2800);
}
</script>`}
            className=""
          ></Script>
        </div>
      </div>
    </div>
  )
}

PagespeedSlider.defaultProps = {
  text12: '~9,79MB 5:50min. (3/3)',
  videoSrc11: '/tutvid/pagespeed2.mp4',
  heading1: 'Videos zur Anwendung',
  heading12: 'Videos zur Anwendung',
  videoSrc1: '/tutvid/pagespeed1.mp4',
  videoSrc12: '/tutvid/pagespeed3.mp4',
  text11: '~8,43MB 5:40min. (2/3)',
  text1: '~6,79MB 5:40min. (1/3)',
  heading11: 'Videos zur Anwendung',
  text: 'Der Pagespeed von Google, der auch in Chrome integriert ist und unter dem Namen Lighthouse bekannt ist, ist das Nonplusultra der Analysemethoden. Mit diesem Tool lassen sich umfassende Erkenntnisse über Websites gewinnen. Der eigentliche Zweck ist die Überprüfung der Performance einer Seite, jedoch können hier auch Schnittmengen für die Nachhaltigkeitsbewertung gewonnen werden.',
  heading: 'Pagespeed',
  rootClassName: '',
}

PagespeedSlider.propTypes = {
  text12: PropTypes.string,
  videoSrc11: PropTypes.string,
  heading1: PropTypes.string,
  heading12: PropTypes.string,
  videoSrc1: PropTypes.string,
  videoSrc12: PropTypes.string,
  text11: PropTypes.string,
  text1: PropTypes.string,
  heading11: PropTypes.string,
  text: PropTypes.string,
  heading: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default PagespeedSlider
